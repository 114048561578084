import React, { useState, useEffect } from "react";
import "../styles/DashboardBankTransfers.css";
import { baseurl, setCookie, getCookie } from "../utils/constants";
import axios from "axios";
import OTPVerification from "./OTPVerification";
import AMLIMFVerification from "./AMLIMFVerification";
import btclogo from "../images/btclogo.png";
import { setUserdata, clearUserdata } from "../redux/userdataSlice";

import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";

export default function DashboardBitcoinTransfers() {
  const [loadinguserDBData, setLoadinguserDBData] = useState(false);
  const [laodingBillingFromat, setLoadBillingFromat] = useState(false);
  const [showBillingFormat, setShowBillingFormat] = useState(false);
  const [billinformatIcon, setLoadBillingFromatIcon] = useState();
  const [billinformatHeader, setLoadBillingFromatHeader] = useState("");
  const [billinformatDescription, setLoadBillingFromatDescription] =useState("");
  const [accountbalance, setAccountBalance]= useState(10);
  const [billingPoint, setBillingPoint] = useState("");
  const [userDBData, setuserDBData] = useState({});
  const navmove = useNavigate();
  let dispatch= useDispatch()

  function formatNumber(number) {
    return number.toLocaleString();
   // return number
}
useEffect(() => {
  setLoadinguserDBData(true);

  if ((document.cookie.indexOf('usersession') !== -1)) {
     async function fetchData() {
    try {
      const user = getCookie("usersession");
      const requestData = {
        requestTask: "getLoginData",
        email: user,
      };
      const result = await axios.post(baseurl, requestData);
      if (result && result.data) {
        setuserDBData(result.data);
        dispatch(setUserdata(result.data));
        setAccountBalance(result.data.Balance)
        console.log("User Data is", result.data);
      }
    } catch (error) {
      alert("Poor internet connection!!!");
      // Handle errors here, e.g., log them or set an error state.
      console.error("Error fetching user data:", error);
    } finally {
      setLoadinguserDBData(false);
    }
  }

  fetchData();
   }

else{
navmove('/login')
}

 
}, []);

  const [formData, setFormData] = useState({
    walletaddress: "",
    network: "",
    amount: "",
    transactionPin: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoadBillingFromat(true);

      setTimeout(() => {
        setLoadBillingFromat(false);
        billingFormat();
        setShowBillingFormat(true);
      }, 2500);
    } else {
      console.log("Form contains errors");
    }
  };

  function billingFormat() {
    // console.log(
    //   `
    //     OTP: ${userDBData.OTP}
    //     Linking: ${userDBData.AccountLinking}
    //     AMLIMF:${userDBData.AMLIMF}
    //     OTPVERFIED:${userDBData.OTPVerified}

    //     First billing = IMS Code (user go enter the IMS code, same with OTP for Safehaven)

    //     2nd billing = Account linking -> "Your account is not linked to our payment processor. Contact customer support"

    //     3rd billing=  Secure Circle Funds withdrawal code-> Require input

    //     4th billing = Account verification (no input). Your account with us is not verified for withdrawal. Reach out to customer support to initiate the verification process

    //     `
    // );
    if (
      (userDBData.OTP == "NULL" &&
        userDBData.AccountVerification == "NOT VERIFIED" &&
        userDBData.AccountLinking == "NOT LINKED" &&
        userDBData.AMLIMF == "NULL" &&
        (userDBData.OTPVerified == "FALSE" || userDBData.OTPVerified == "")) ||
      (userDBData.OTP !== "NULL" &&
        userDBData.AccountVerification == "NOT VERIFIED" &&
        userDBData.AccountLinking == "NOT LINKED" &&
        userDBData.AMLIMF == "NULL" &&
        (userDBData.OTPVerified == "FALSE" || userDBData.OTPVerified == ""))
    ) {
      setBillingPoint("OTP");
    } else if (
      userDBData.OTP !== "NULL" &&
      userDBData.AccountLinking == "NOT LINKED" &&
      userDBData.AccountVerification == "NOT VERIFIED" &&
      userDBData.AMLIMF == "NULL" &&
      userDBData.OTPVerified == "TRUE"
    ) {
      setBillingPoint("Account Linking");
      setLoadBillingFromatHeader("Account Linking Required");
      setLoadBillingFromatIcon(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            width="83px"
            height="83px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <g opacity="0.5">
                {" "}
                <path
                  d="M19.6879 4.15777C16.9672 1.61408 12.5756 1.61408 9.85484 4.15777L9.48781 4.50092C9.18524 4.7838 9.16928 5.25841 9.45216 5.56098C9.73505 5.86355 10.2097 5.87951 10.5122 5.59663L10.8792 5.25348C13.0234 3.24884 16.5193 3.24884 18.6635 5.25348C20.7789 7.23122 20.7789 10.4155 18.6635 12.3933L16.4613 14.4521C16.1587 14.735 16.1428 15.2096 16.4257 15.5122C16.7086 15.8148 17.1832 15.8307 17.4857 15.5479L19.6879 13.489C22.4374 10.9184 22.4374 6.72837 19.6879 4.15777Z"
                  fill="#1C274C"
                ></path>{" "}
                <path
                  d="M6.17183 10.5347C6.46716 10.2443 6.47114 9.76946 6.18071 9.47413C5.89027 9.17879 5.41542 9.17482 5.12008 9.46525L4.21509 10.3552C1.54336 12.9826 1.61504 17.2094 4.31376 19.7901C7.02135 22.3793 11.4361 22.4174 14.1529 19.8194L14.5184 19.4699C14.8177 19.1836 14.8283 18.7089 14.5421 18.4095C14.2558 18.1101 13.781 18.0995 13.4817 18.3858L13.1162 18.7353C10.9885 20.77 7.48737 20.7494 5.35045 18.706C3.25251 16.6998 3.20945 13.448 5.26684 11.4247L6.17183 10.5347Z"
                  fill="#1C274C"
                ></path>{" "}
              </g>{" "}
              <path
                d="M7.28013 2.71316C7.12174 2.33042 6.68307 2.14856 6.30034 2.30695C5.9176 2.46534 5.73574 2.90401 5.89413 3.28675L6.80674 5.49195C6.96514 5.87469 7.40381 6.05655 7.78654 5.89816C8.16927 5.73977 8.35114 5.3011 8.19274 4.91836L7.28013 2.71316Z"
                fill="#1C274C"
              ></path>{" "}
              <path
                d="M3.83111 5.41254C3.45887 5.23084 3.00982 5.38531 2.82813 5.75755C2.64643 6.12979 2.8009 6.57884 3.17314 6.76053L8.72097 9.46848C9.09321 9.65017 9.54226 9.4957 9.72395 9.12347C9.90564 8.75123 9.75118 8.30218 9.37894 8.12049L3.83111 5.41254Z"
                fill="#1C274C"
              ></path>{" "}
            </g>
          </svg>
        </div>
      );
      setLoadBillingFromatDescription(
        "To proceed with your withdrawal, you are required to link your account. Please complete this step or reach out to our support team for assistance."
      );
    }
    
    else if (
      userDBData.OTP !== "NULL" &&
      userDBData.AccountVerification == "NOT VERIFIED" &&
      userDBData.AccountLinking == "LINKED" &&
      userDBData.AMLIMF == "NULL"|| userDBData.AMLIMF !== "TRUE"  &&
      userDBData.OTPVerified == "TRUE"
    ) {
      setBillingPoint("AML");
    }
    
    else if (
      userDBData.OTP !== "NULL" &&
      userDBData.AccountLinking == "LINKED" &&
      userDBData.AMLIMF === "TRUE" &&
      userDBData.AccountVerification == "NOT VERIFIED" &&
      
      userDBData.OTPVerified == "TRUE"
    ) {
      setBillingPoint("Account Verification");
      setLoadBillingFromatHeader("Account Not Verified");
      setLoadBillingFromatIcon(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            width="89px"
            height="89px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <circle
                cx="12"
                cy="6"
                r="4"
                stroke="#1C274C"
                strokeWidth="1.5"
              ></circle>
              <circle
                cx="18"
                cy="16"
                r="4"
                stroke="#1C274C"
                strokeWidth="1.5"
              ></circle>
              <path
                d="M16.6665 16L17.5 17L19.3332 15.1111"
                stroke="#1C274C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M15 13.3271C14.0736 13.1162 13.0609 13 12 13C7.58172 13 4 15.0147 4 17.5C4 19.9853 4 22 12 22C17.6874 22 19.3315 20.9817 19.8068 19.5"
                stroke="#1C274C"
                strokeWidth="1.5"
              ></path>
            </g>
          </svg>
        </div>
      );
      setLoadBillingFromatDescription(
        "To continue, your account must be verified. If you encounter any issues, please contact support for assistance."
      );
    }
     

    console.log("THE BILLING POINT IS: " + billingPoint);
  }
  const validateForm = () => {
    const newErrors = {};
    // Simple validation, checking if fields are empty
    if (!formData.walletaddress.trim()) {
      newErrors.bankName = "Wallet address is required";
    }

    if (!formData.amount.trim()) {
      newErrors.amount = "Amount is required";
    }
    if (!formData.network.trim()) {
      newErrors.amount = "Network is required";
    }
    if (Number(formData.amount.trim()) > 500000) {
      newErrors.amount =
        "Withdrawal limit exceeded. Contact support to increase limit.";
    }
    if (Number(formData.amount.trim()) > Number(userDBData.Balance)) {
      newErrors.amount = "insufficient funds";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function BillingInfoComponent() {
    return (
      <div className="billingInfoComponent">
        <p className="billingformaticon">{billinformatIcon}</p>
        <h1>{billinformatHeader}</h1>
        <p>{billinformatDescription}</p>
      </div>
    );
  }

  return (
    <div>
      <div style={{
                      display:"flex",
                      padding:"60px 0",
                      justifyContent:"center",
                      alignItems:'center',
                      flexDirection:"column"
                    }}
                    data-aos="fade-in" data-aos-duration="2000"
                    >
                      <img style={{width:"50px"}} src={btclogo} alt="btclogo"/>
                      <p>Balance:{userDBData.currency} {formatNumber(accountbalance)} </p>
                    </div>
      {loadinguserDBData ? (
        <i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>
      ) : (
        <div className="DashboardBankTransfers">
          {userDBData.Balance == 0 ? (
            <div className="empty-balance">
              <h2>Please deposit funds into your account.</h2>
            </div>
          ) : (
            <div>
              {showBillingFormat ? (
                <div className="billinformat">
                  <div className="withdrawal-wrapper">
                    <div className="widrawal-header">
                      <i class="fa-solid fa-circle-exclamation fa-fade"></i>
                      <h1>Processing Withdrawals</h1>
                    </div>

                    <div className="withdral-detail-container">
                      <p className="withdrawalDetail">
                        <span className="withdrawalDetailTittle">From: </span>
                        {userDBData.AccountNumber}
                      </p>
                      <p className="withdrawalDetail">
                        <span className="withdrawalDetailTittle">To: </span>
                        {formData.walletaddress}
                      </p>
                      <p className="withdrawalDetail">
                        <span className="withdrawalDetailTittle">
                          Network:{formData.network}
                        </span>
                        {formData.bankName}
                      </p>
                      <p className="withdrawalDetail">
                        <span className="withdrawalDetailTittle">Amount: </span>
                        {` ${userDBData.currency}`} {formatNumber(formData.amount)}
                      </p>
                     
                      <div className="Deduction-Summary">
                        <p>Total:</p>
                        <h1> -{` ${userDBData.currency}`} {formatNumber(formData.amount) +".00"}</h1>
                      </div>
                    </div>
                  </div>

                  {billingPoint == "OTP" ? (
                    <OTPVerification userData={userDBData} />
                  ) : billingPoint == "Account Linking" ? (
                    <BillingInfoComponent />
                  ) :  billingPoint == "AML" ?  (
                    <AMLIMFVerification userData={userDBData} />
                  ):
                  
                    <BillingInfoComponent />
                  }
                </div>
              ) : (
                <div>
                  {Object.keys(errors).length > 0 && (
                    <div className="error-container">
                      <p className="error-lable">
                        Please fix the following errors:
                      </p>
                      <ul>
                        {Object.values(errors).map((error, index) => (
                          <li className="error-item" key={index}>
                            - {error}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  
                  <div>
                    
                  <form onSubmit={handleSubmit}data-aos = "slide-up" data-aos-duration="200">
                  You're about to transfer from your account's available balance. This action cannot be reversed. Be sure to enter correct details.
                    <div className="form-field">
                      <label htmlFor="walletaddress">Bitcoin wallet Address*</label>
                      <input
                        type="text"
                        id="walletaddress"
                        name="walletaddress"
                        value={formData.walletaddress}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-field">
                      <label htmlFor="network">Network</label>
                      <select
                        id="network"
                        name="network"
                        value={formData.network}
                        onChange={handleChange}
                      >
                        <option value="">Select Network</option>
                        <option value="TRC20">BTC</option>
                        <option value="ERC20">TRC</option>
                        <option value="ERC20">ERC20</option>
                        <option value="BEP20">BEP20</option>
                        <option value="EOS">EOS</option>
                      </select>
                    </div>
                    <div className="form-field">
                      <label htmlFor="amount">Amount*</label>
                      <input
                        type="number"
                        id="amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                      />
                    </div>

                    <button type="submit">
                      {laodingBillingFromat ? (
                        <i class="fa-solid fa-spinner fa-spin"></i>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                  </div>


                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
